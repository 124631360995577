import { useAuth0 } from '@auth0/auth0-react';
import { createContext, useContext, useEffect, useLayoutEffect, useState } from 'react';
import ProfileService from '../api/services/profileService';
import { useNavigate } from 'react-router-dom';
import { Roles, paths } from '../common/constants';
import { checkIsDeviceAuthToken } from '../common/utils/functions';

interface Props {
    children: JSX.Element, 
}

const AuthContext = createContext<AuthContextInterface>({
    isAuth: false,
    userData: null, 
    isLoading: false, 
    isInit: false,
    user: null, 
    logoutHandler: () => null,
    login: (type: 'signup' | 'login' | 'external') => null, 
    setUserData: (data: User | null) => null, 
    isDeviceAuthToken: false, 
    isCheckedRNAuth0: false, 
});

export function useAuthContext() {
    return useContext(AuthContext);
}

const AuthProvider = ({ children }: Props) => {
    const [isAuth, setIsAuth] = useState(false); 
    const [isInit, setIsInit] = useState(false); 
    const [userData, setUserData] = useState<User | null>(null);
    const [isCheckedRNAuth0, setIsCheckedRNAuth0] = useState(false); 
    const [isDeviceAuthToken, setIsDeviceAuthToken] = useState(false); 
    const { isAuthenticated, isLoading, getAccessTokenSilently, loginWithRedirect, logout, user } = useAuth0();
    const navigate = useNavigate(); 

    useLayoutEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }, [isAuthenticated]);
      
    const logoutHandler = () => {
        logout({ logoutParams: {
            returnTo: window.location.origin
        }}); 
        sessionStorage.removeItem('token'); 
        localStorage.removeItem('item_id'); 
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if(window?.ReactNativeWebView) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
            window?.ReactNativeWebView?.postMessage('logout');
        }
    }

    const handlerLoginWithToken = async (token?: string, type: 'base' | 'rn' = 'base') => {
        console.log('user', user)
        try {
            const response:any = await ProfileService.getProfileBySubId(); 
            if(response) {
                 setUserData({...response, userRole: response?.role[0]?.role}); 
                 // setUserData({...response, userRole: Roles.EXPERT});  
            }
            setIsInit(true); 
            setIsAuth(true);
        } catch (error) {
            console.log('profile not found')
             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
            if(window?.ReactNativeWebView) {
                // sessionStorage.removeItem('token');
                // setIsInit(false); 
                setIsInit(true); 
                sessionStorage.removeItem('token');
                navigate(`/${paths.UNKNOWN_ERROR}`);
            } else {
                // sessionStorage.removeItem('token');
                setIsInit(true); 
                setIsAuth(isAuthenticated); 
                navigate(`/${paths.UNKNOWN_ERROR}`);
            }
        }  finally {
             // eslint-disable-next-line @typescript-eslint/ban-ts-comment
             // @ts-expect-error
            if(window?.ReactNativeWebView && type === 'rn') {
                setIsCheckedRNAuth0(true); 
            }
          
        }
    }

    const login = async (type: 'signup' | 'login' | 'external') => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
        if (window?.ReactNativeWebView) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            window?.ReactNativeWebView?.postMessage('login');
          } else {
            if(type === 'external') {
                const item_id_by_device = localStorage.getItem('item_id') ?? ''; 
                console.log('test-2')
                try {
                    const token = await getAccessTokenSilently({
                        authorizationParams: {
                            screen_hint: type, 
                            redirect_uri: `${window.location.origin}/item_external`,
                            'item_id': item_id_by_device,
                        }
                    }); 
                    if(token) {
                        sessionStorage.setItem('token', token); 
                        setIsDeviceAuthToken(checkIsDeviceAuthToken(token));
                        setIsAuth(true); 
                        setIsInit(true);
                    }  else {
                        navigate('dashboard/items'); 
                    }
                } catch (error: any) {
                    if(error?.error === 'login_required') {
                        loginWithRedirect({
                            authorizationParams: {
                            screen_hint: type, 
                            redirect_uri: `${window.location.origin}/item_external`,
                                'item_id': item_id_by_device,
                        }}); 
                    } 
                }

            } else {
                loginWithRedirect({
                    authorizationParams: {
                    screen_hint: type, 
                }}); 
            }

          }
    }

    const handleMessage = (event: any) => {
        const message = event?.data;
        if (message.token) {
          sessionStorage.setItem('token', message.token); 
          handlerLoginWithToken('', 'rn'); 
          return;
        }
        if(message.checkedAuth) {
            setIsCheckedRNAuth0(true); 
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            if(window?.ReactNativeWebView) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                    window?.ReactNativeWebView?.postMessage('got message');
                }
            }
      };
    
    const handleAuth = async (isAuth: boolean) => {
        try {
            console.log('test-1')
            const tokenStorage = sessionStorage.getItem('token');
            const storageItemId = localStorage.getItem('item_id'); 
            if(storageItemId && !isAuth && window.location.pathname !== '/dp_external_code') {
                login('external'); 
                return;
            } 
            if(tokenStorage) {
                handlerLoginWithToken(tokenStorage); 
                setIsDeviceAuthToken(checkIsDeviceAuthToken(tokenStorage));
                return; 
            } 
            const token = await getAccessTokenSilently(); 
            if(token) {
                sessionStorage.setItem('token', token);
                setIsDeviceAuthToken(checkIsDeviceAuthToken(token));
                handlerLoginWithToken(token); 
            } 
        } catch (error) {
            console.log('error', error);     
        }
    }  

     useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        if(window?.ReactNativeWebView) {
            console.log('react-native-auth-flow')
            handleAuth(isAuthenticated);
        } else {
            handleAuth(isAuthenticated);
        }
/*         if(isAuthenticated) {
            handleAuth(isAuthenticated);
        } */
    }, [isAuthenticated]); 

    useEffect(() => {
        if(!isLoading && !isAuthenticated) {
            setIsInit(true); 
            setIsAuth(isAuthenticated); 
        }
    }, [isLoading, isAuthenticated]);
  
    const value = { isAuth, userData, isLoading, logoutHandler, login, isInit, setUserData, isDeviceAuthToken, user, isCheckedRNAuth0 };
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
} 

export default AuthProvider; 

