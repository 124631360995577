import { useState } from 'react';
import { ReactComponent as PlusIcon } from '../../assets/images/plus_blue.svg';
import { ReactComponent as MinusIcon } from '../../assets/images/minus-line.svg';
import {ReactComponent as OpenedMenu}from '../../assets/images/close_menu_1.svg';
import {ReactComponent as ClosedMenu}from '../../assets/images/open_menu.svg';
import logo from '../../assets/images/nftrends_logo_new.svg';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { paths } from '../../common/constants';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { handleSuccessNotify } from '../../common/utils/handleSuccessNotify';
import ItemsService from '../../api/services/itemsService';
import { handleError } from '../../common/utils/handleError';
import { useWindowSize } from 'usehooks-ts';
import { anchors, follow_us_icons, updatedNewsData } from './data';
import { scrollToTop } from '../../common/utils/functions';
import { Carousel } from 'react-responsive-carousel';

export const TeamCard = ({ item }: any) => {
  const [open, setOpen] = useState(false);
  return (
    <div className=''>
      <div className='w-full flex justify-start'>
        <img
          src={item.image}
          alt='image'
          className='w-full lg:w-[288px] h-auto lg:h-[288px] object-cover'
        />
      </div>
      <div className='pt-[16px] text-[20px] raleway-600 leading-[30px]'>
        {item.title}
      </div>
      <div className='pt-[16px] pr-2 text-[14px] raleway-500 leading-[21px] uppercase'>
        {item.role}
      </div>
      <div
        className={clsx('pt-[16px] text-[16px] raleway-400 leading-[24px] ', {
          'h-[140px] lg:h-[140px] overflow-y-hidden text-gradient-custom bg-gradient-to-b from-[#1A1A1A] to-opacity-0':
            !open,
          'max-h-max': open,
        })}
      >
        {item.text}
      </div>
      <div>
        <button
          className='text-[#01155C] flex space-x-1'
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          {!open ? <PlusIcon /> : <MinusIcon />}{' '}
          <span className='underline'>{open ? 'Read less' : 'Read more'}</span>
        </button>
      </div>
    </div>
  );
};

export const InfoSection = () => {
  const { t } = useTranslation();
  const data = [
    {
      title: t('main.info_subtitle_1'),
      text: t('main.info_text_1'),
      url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/info-1.png',
    },
    {
      title: t('main.info_subtitle_2'),
      text: t('main.info_text_2'),
      url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/info-2.png',
    },
    {
      title: t('main.info_subtitle_3'),
      text: t('main.info_text_3'),
      url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/info-3.png',
    },
    {
      title: t('main.info_subtitle_4'),
      text: t('main.info_text_4'),
      url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/info-4.png',
    },
  ];
  return (
    <div className='w-full space-y-[48px] lg:space-y-[65px]'>
      {data.map((item, idx) => (
        <div key={idx} className='relative w-full flex flex-col lg:flex-row space-y-2 lg:space-y-0'>
          <div className='w-[100px] absolute lg:static top-[20px] left-[9px]'>
            <div className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center text-[16px] leading-none text-[#1A1A1A] raleway-600 rounded-full'>
               <span className="inline-block translate-y-[-1px]">{idx + 1}</span>
            </div>
          </div>
          <div className='w-full lg:w-1/6'>
            <img src={item.url} alt='image' className='w-full lg:w-auto'/>
          </div>
          <div className='w-full lg:w-1/4 grow raleway-600 text-[22px] lg:text-[26px] lg:pl-[32px] lg:pr-4 leading-[30px] pt-[7px] lg:pt-auto'>{item.title}</div>
          <div className='w-full lg:w-1/4 grow raleway-500 text-[16px] leading-[24px]'>{item.text}</div>
        </div>
      ))}
    </div>
  );
};

interface Inputs {
  name: string;
  email: string; 
  message: string; 
}

export const ContactUsForm = () => {
  const {
    register,
    handleSubmit,
    reset, 
    formState: { errors },
  } = useForm<Inputs>(); 
  const {t} = useTranslation(); 
  const {width} = useWindowSize();
  const [loading, setLoading] = useState(false); 
  const isDesktop = width > 1024; 

  const sendData = async (data: any) => {
    try {
      setLoading(true); 
      await ItemsService.faqSend(data); 
      handleSuccessNotify('Thank you for contacting us. We look forward to being in touch shortly to address any questions you may have.'); 
      reset();
    } catch (error: any) {
      handleError(error?.message); 
    } finally {
      setLoading(false); 
    }
  }

  function listenForEnter(e: React.KeyboardEvent) {
    console.log('e.key', e.key)
    if (e.keyCode === 13 && !e.shiftKey) {
      handleSubmit(onSubmit)(); 
      e.preventDefault();
      return false;
    }
  }

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    console.log('data', data); 
    sendData(data); 
  }
    return (
        <form className='w-full' onSubmit={handleSubmit(onSubmit)} onKeyDown={listenForEnter}>
            <div className='w-full flex flex-col lg:flex-row lg:space-x-[32px]'>
                <div className='w-full lg:w-[40%]'>
                  <input {...register('name', { required: true })}  type='text' placeholder='Name' className={clsx('w-full bg-[#F2F2F2] rounded-lg h-[52px] lg:h-[62px] pl-[16px] outline-none', {
                    'border border-[#FC4324]': errors.name
                  })}/>    
                </div>    
                <div className='w-full lg:w-[60%] pt-[20px] lg:pt-0'>
                  <input {...register('email', { required: true })}  type='text' placeholder='E-mail' className={clsx('w-full bg-[#F2F2F2] rounded-lg  h-[52px] lg:h-[62px] pl-[16px] outline-none', {
                    'border border-[#FC4324]': errors.email
                  })}/>    
                </div>  
            </div>
            <div className='pt-[20px] lg:pt-[32px] w-full'>
                <textarea {...register('message', { required: true })}  placeholder='Message' className={clsx('w-full bg-[#F2F2F2] rounded-lg pl-[16px] pt-[16px] outline-none resize-none', {
                    'border border-[#FC4324]': errors.message
                  })} rows={isDesktop ? 6 : 3}/>
            </div>
            <div className='pt-[16px] lg:pt-[32px] text-center lg:text-left'>
                <button type='submit' className={clsx('rounded-[40px] text-white text-[18px] raleway-600 py-[16px] px-[32px]', {
                  'bg-[#01155C]/50': loading, 
                  'bg-[#01155C]': !loading
                })}>Send</button>
            </div> 
        </form>
    )
}

interface NavbarLgProps {
  directionScroll: 'up' | 'down';
  handleLogin: (type: 'signup' | 'login') => void; 
}

export const NabvarMainLg = ({directionScroll, handleLogin}: NavbarLgProps) => {
  const {t} = useTranslation();
  const {width} = useWindowSize();

  return (
    <div
    className={`hidden lg:flex fixed z-[100] w-auto h-[64px] rounded-[40px] items-center justify-between pl-[24px] pr-[8px] transition ease-in-out delay-150 bg-white shadow-md ${
      directionScroll === 'up' ? 'translate-y-[20px]' : '-translate-y-[83px]'
    }`}
  >
    <div className='w-1/5 pr-[40px]' onClick={scrollToTop}>
      <img src={logo} alt='logo' className={`${width < 1250 ? 'lg:h-[30px]' : 'lg:h-[40px]'} 2.5xl:h-[50px] cursor-pointer`}/>
    </div>

    <nav>
      <ul className={'flex text-[#1A1A1A] raleway-500 text-[18px] space-x-[32px]'}>
        <li>
            <a href={`#${anchors.SOLUTIONS}`}>Solutions</a>
          </li>
        <li>
          <a href={`#${anchors.TEAM}`}>Team</a>
        </li>
        <li>
          <a href={`#${anchors.NEWS}`}>News</a>
        </li>
         <li>
          <a href={`#${anchors.CONTACT}`}>Contact</a>
        </li> 
      </ul>
    </nav>

    <div className='pl-[40px] space-x-[16px] flex'>
      <button
        className={'w-[127px] raleway-600 text-[15px] text-[#fff] bg-[#01155C] rounded-[40px] py-[12px] px-[20px] '}
        onClick={() => {
          handleLogin('login');
        }}
      >
        Client login
      </button>
{/*        <a
        href={`#${anchors.CONTACT}`}
        className={'raleway-600 text-[15px] text-[#fff] bg-[#01155C] rounded-[40px] py-[12px] px-[20px] block'}
      >
        Get in touch
      </a>  */}
    </div>
  </div>
  )
}

interface NavbarMobileProps {
  directionScroll: 'up' | 'down';
  handleLogin: (type: 'signup' | 'login') => void; 
}

export const NavbarMainMobile = ({directionScroll, handleLogin}: NavbarMobileProps) => {
  const {t} = useTranslation();
  const [openMenu, setOpenMenu] = useState(false); 
  return (
    <div
    className={`lg:hidden ${openMenu ? 'bg-[#fff]' : ''}  fixed z-[200] w-full px-[8px] ${!openMenu ? 
      directionScroll === 'up' ? 'translate-y-[8px]' : '-translate-y-[83px]' : 'translate-y-[0px]'
    }`}
  >
    <div className={clsx('flex h-[48px] rounded-[40px] shadow-md w-full items-center justify-between  py-[15px] px-[16px]', {
      'bg-white': !openMenu, 
      'bg-[#F2F2F2] mt-[100px]': openMenu
    })}>
      <div className='w-1/2'>
        <img src={logo} alt='logo' className='h-[22px] w-auto'/>
      </div>
      <div>
          {openMenu ? <OpenedMenu onClick={() => { setOpenMenu(false); }}/> : <ClosedMenu onClick={() => { setOpenMenu(true); }}/>}
        </div>  
    </div>
    {openMenu && <div className='h-screen px-[12px] text-[#1A1A1A]'>
      <div className='flex text-[15px] space-x-[16px]'>
      </div>
        <nav>
          <ul className='flex flex-col text-[#1A1A1A] leading-[30px] text-[20px] raleway-500 space-y-[16px] pt-[16px]'>
            <li className='border-b border-[#E6E6E6] py-[16px]'>
             <a className='w-full h-full block' href={`#${anchors.SOLUTIONS}`} onClick={() => { setOpenMenu(false); }}>Solutions</a>
            </li>
            <li className='border-b border-[#E6E6E6] py-[16px]'>
              <a  className='w-full h-full block' href={`#${anchors.TEAM}`} onClick={() => { setOpenMenu(false); }}>Team</a>
            </li>
            <li className='border-b border-[#E6E6E6] py-[16px]'>
              <a className='w-full h-full block' href={`#${anchors.NEWS}`} onClick={() => { setOpenMenu(false); }}>News</a>
            </li>
             <li className='border-b border-[#E6E6E6] py-[16px]'>
              <a className='w-full h-full block' href={`#${anchors.CONTACT}`} onClick={() => { setOpenMenu(false); }}>Contact</a>
            </li> 
          </ul>
      </nav>
      <div className=' pt-[24px] space-x-[16px] flex w-full'>
        <button
          className={'raleway-600 text-[15px] text-[#fff] bg-[#01155C] rounded-[40px] py-[12px] px-[20px] w-full'}
          onClick={() => {
            handleLogin('login');
          }}
        >
          Client login
        </button>
{/*          <a
          href={`#${anchors.CONTACT}`}
          className={'raleway-600 text-[15px] text-[#fff] bg-[#01155C] rounded-[40px] py-[12px] px-[20px] block w-1/2 text-center'}
        >
          Get in touch
        </a>  */}
      </div>
    </div>}

  </div>
  )
}

export const NewsSectionMainMobile = () => {
  const {t} = useTranslation(); 
  return (
    <div className='lg:hidden w-full'>
          <Carousel showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true} swipeable emulateTouch={true} showIndicators>
            {updatedNewsData.map(item => <div key={item.id} className={'w-full h-[400px] inset-y-0 px-1 cursor-pointer'} onClick={() => {
              if(item.url_redirect) {
                window.open(item.url_redirect, '_blank')
              }

            }}>
              <img 
                src={item.url} 
                className={'h-[200px] lg:h-full w-auto object-cover pointer-events-none '}
                alt='images'/>
    
                <div className='text-left pt-[16px] text-[#808080] raleway-500 text-[16px]'>
                {item.date}
                </div>
       
                <div className='w-full text-[16px] raleway-600 text-justify py-[9px] line-clamp-3 text-[#1A1A1A] leading-[30px]'>
                  {item.title}
                </div>
            </div>)}
          </Carousel>
    </div>
  )
}

export const FollowUsMobile = () => {
  return (
    <div className='lg:hidden w-full'>
          <Carousel showThumbs={false} showStatus={false} showArrows={false} infiniteLoop={true} swipeable emulateTouch={true} showIndicators={false} centerSlidePercentage={80} centerMode={true}>
            {follow_us_icons.map((item, idx) => <div key={idx} className={'w-full h-auto inset-y-0 px-1'}>
              <img 
                src={item} 
                className={'h-full w-full pointer-events-none '}
                alt='images'/>
            </div>)}
          </Carousel>
    </div>
  )
}
