export const teamData = [
    {
        image: 'https://storage.googleapis.com/dev-nftrends-media/T-Marsha-448.jpg',
        name: 'Marsha Lipton, PhD',
        position: 'CEO',
        info: [
            'Managing Partner at Numéraire Financial, Inc.',
            '25+ years of capital markets experience',
            'Former Managing Director of JPMorgan Chase',
            'Former head trader at DLJ',
            'Ph.D. in Physical Chemistry, University of Chicago and MBA, The University of Chicago Booth School of Business'
        ]
    },
    {
        image: 'https://storage.googleapis.com/dev-nftrends-media/T-Thomas%20Hardjono-450.jpg',
        name: 'Thomas Hardjono, PhD',
        position: 'CTO',
        info: [
            'MIT Connection Science & MIT Trust-Data Consortium — technical  research director',
            'Director of MIT Kerberos Network Authentification systems. Software used by 70%  of Enterprise systems globally',
            '25+ years in senior technical positions in industry',
            'Principal Scientist at Verisign/Symatec. Chief Scientist Wave Trusted Hardware. CTO of SignaCert/Harris Defence',
            '70+ technical papers; 5 books on cybersecurity. 40+ patents issued'
        ]
    },
    {
      image: 'https://storage.googleapis.com/dev-nftrends-media/T-1716636502769%20(1).jpg',
      name: 'Christina Mesropian, PhD',
      position: 'VP of AI and Machine Learning',
      info: [
          '15+ years as Big Data, ML, and AI researcher with vast industrial experience',
          'Founder of Quant Data Solutions',
          'Scientific researcher at Argon Labs and CERN',
          'Ph.D. in Particle Physics, Rockefeller University',
      ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-Hikmat-Al-Kaitoob-450.png',
    name: 'HE Hikmat Al Kaitoob',
    position: 'Chief Strategic Advisor to CEO',
    info: [
      'Founder of Hikmat Al Kaitoob Holding and Hikmat Al Kaitoob Marketing',
      'Ambassador and Consultant to International Luxury Brands, including Piaget, Tiffany, Givenchy, Schiaparelli, Roberto Coin, Cotty Group, and Gucci, among many others',
      'Advisor to several museums, including Ansorena Museum and Esmod Fashion University',
      'Chief Committee Officer and Advisor to the Board, Arab Fashion Council',
      'Winner of Best 50 GCC Leaders Award',
      'UN Goodwill Peace Ambassador',
      'MBA in Leadership and Management', 
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-1649117222700.png',
    name: 'Patrick Foret',
    position: 'Advisory Board Member',
    info: [
      'Founder and Principal of Cultural Enterprise, cultural development strategy for private and public art organizations, as well as luxury brands.',
      'Art Basel Former Director of Business Initiatives and Partnerships, and Member of the Executive Committee.',
      'BCG, Senior Advisor on Cultural Policy Development. Design Miami Senior Advisor on Global Development',
      'Tourism Development and Investment Company, Cultural Department Business Manager, overseeing the Louvre Abu Dhabi and the Guggenheim Abu Dhabi'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-12131212.jpg',
    name: 'Alex Boiko',
    position: 'VP of Engeneering',
    info: [
        '6+ years as Senior Manager with vast industrial experience',
        'Co-Founder of TAG, a software development group',
        'Co-Founder and Head of Engineering at several companies',
        'Information Security Specialist',
        'Strategic planner, resource allocator, and performance manager in high-profile technology projects',
        'MSc in Engineering, specializing in Technical Data Protection, Donetsk National Technical University'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/Tatiana_gecmen_valdek.png',
    name: 'HE Tatiana Gecmen-Waldek',
    position: 'Advisory Board Member',
    info: [
        '15+ years of experience in art consultancy, specializing in ultra-contemporary art',
        'Creative Director at Serpetinis and Curator for Joali Resort Maldives',
        'Client portfolio includes Giorgio Armani, Etro, Roger Vivier, Cartier, Maison Kitsuné, Tod\'s, Vhernier, and Diptyque',
        'Member of the Artistic Committee of Asia Now Art Fair in Paris',
        'Produced and curated multiple exhibitions in major European cities',
        'Under the pseudonym BadBadTati works closely with video artists, electronic music producers, and luxury brands'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/katerina_kashtan.png',
    name: 'Katerina Kashtan',
    position: 'Advisory Board Member',
    info: [
      '15+ years of experience in global art markets',
      'Partner in Hedonist gallery in Dubai, representing iconic Spanish modern artists',
      'Over 50 international projects and exhibitions',
      'Founder of Authentic Boutique LLC, a boutique consultancy agency building cross-cultural relationships in the Middle East',
      'Master‘s degree in Culture and Religion science, Art of the Modern Period from UvA',
      'MBA in Exponential innovation from Singularity University, which promotes synergies between art, marketing, and technology'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/Juli%20Cho%20Bailer%20headshot%202024%20copy.jpg',
    name: 'Juli Cho Bailer',
    position: 'Advisory Board Member',
    info: [
      'Korean-American curator, writer, and ArtTech entrepreneur based in Zurich',
      'Pioneering figure in merging AI and blockchain technology within the art industry',
      'Co-founder and former CEO of SmartStamp, an ArtTech startup developing AI- and blockchain-based solutions for art and collectibles',
      'Brings 25+ years of combined museum and market experience, serving in prestigious positions at notable institutions, including the Whitney Museum, the Jewish Museum in New York, and the Museum of Glass in Washington',
      'Curated a distinguished array of museum exhibitions and installations, working with many renowned artists',
      'Authored three exhibition catalogues available on Amazon'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/sarah_malik.png',
    name: 'Sarah Malik',
    position: 'FCIArb, Advisory Board Member',
    info: [
      'Sarah Malik is an award-winning lawyer and founder of SOL International Ltd',
      'Finalist at the First Middle East Blockchain Awards 2022 for ‘Most Influential Woman in Blockchain and Crypto',
      'Adviser for female tech startups for She Wins Arabia, a joint initiative between International Finance Corporation and ADGM',
      'Frequent speaker at conferences within the UAE, GCC and internationally. She trains various academies, institutions, and judiciary worldwide and is a visiting lecturer on the LLM at Middlesex University, Dubai'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-images%20(6).jpeg',
    name: 'Prof. Alexander Lipton',
    position: 'Advisory Board Member',
    info: [
      'Global Head of Quantitative Research and Development and Portfolio Oversight at ADIA',
      'Professor of Practice, The Hebrew University of Jerusalem & Khalifa University',
      'MIT Connection Science Fellow',
      'Ex-Managing Director, Co-head of Quantitative Solutions Group @ Bank of America'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-image%20(16).png',
    name: 'Prof. Alex \'Sandy\' Pentland',
    position: 'Advisory Board Member',
    info: [
      'Founder and Director of MIT Connection Science',
      'Professor, MIT (Media Lab, Sloan Business School, Institute for Data, Systems, and Society)',
      'Board of Directors, UN Global Partnership for Sustainable Development Data'
    ]
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-StuartHaber.jpg',
    name: 'Stuart Haber, PhD',
    position: 'Advisory Board Member',
    info: [
      'One of the greatest cryptographers of our time',
      'Inventor of blockchain (!) Supposed to be Satoshi Nakamoto or a part of the team known as Satoshi Nakamoto, the role he vigorously denies',
      'President, Stuart Haber Crypto, LLC',
      'Senior Research Engineer, Hewlett Packard Enterprise'
    ]
  },
    
]; 

export const teamBussinesData = [
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-Marsha-448.jpg',
    title: 'Marsha Lipton, PhD',
    role: 'CO-FOUNDER & CHIEF EXECUTIVE OFFICER', 
    text: `In addition to her Founding CEO role at NFtrends, Marsha is also a Managing Partner at Numéraire Financial, Inc., with over 25 years of distinguished experience in capital markets across the U.S. and Europe. Her previous roles include Managing Director at JPMorgan Chase, Head Trader at DLJ, and Manager of the hedge fund Thor Capital Management. 
    In collaboration with renowned MIT professors, Marsha has co-authored several patents on cutting-edge technologies such as Digital Product Passports and Digital Product Fingerprints. Marsha holds a PhD in Physical Chemistry and an MBA from the University of Chicago Booth School of Business.`
  }, 
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/Juli%20Cho%20Bailer%20headshot%202024%20copy.jpg',
    title: 'Juli Cho Bailer',
    role: 'CHIEF STRATEGY OFFICER',
    text: 'Juli is a Korean-American curator and a pioneer in integrating deep technologies within the art world. In 2018, she co-founded and served as CEO of SmartStamp, a Swiss AI and blockchain startup dedicated to driving innovation in the art sector. Prior to her ArtTech journey, Juli held leadership roles at prestigious institutions, including the Whitney Museum and the Jewish Museum in New York, where she served as Collections Manager. As the Curator of the Museum of Glass in Washington, she played a pivotal role in shaping the museum\'s curatorial direction. Juli\'s portfolio includes exhibitions and installation commissions featuring acclaimed artists such as Fred Wilson, Kiki Smith, Mona Hatoum, Maya Lin, Hans Op de Beeck, Jean Michel Othoniel, and Daniel Arsham. Her work has been documented in three museum-published catalogues, available on Amazon.'
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-Hikmat-Al-Kaitoob-450.png',
    title: 'HE Hikmat Al Kaitoob',
    role: 'Chief Advisor to the CEO', 
    text: 'Hikmat Al Kaitoob is the Founder of Hikmat Al Kaitoob Holding and Hikmat Al Kaitoob Marketing, renowned for her extensive expertise in luxury brand consulting and leadership. She has served as an ambassador and consultant for prestigious global brands, including Piaget, Tiffany, and Gucci. Beyond her work in luxury, Hikmat advises cultural institutions such as the Ansorena Museum and Esmod Fashion University. A key figure at the Arab Fashion Council, she holds the roles of Chief Committee Officer and Board Advisor. Her contributions to the industry have earned her recognition as one of the Best 50 GCC Leaders, and she proudly serves as a UN Goodwill Peace Ambassador. Hikmat holds an MBA in Leadership and Management.'
  },
/*   {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/katerina_kashtan.png',
    title: 'Katerina Kashtan',
    role: 'Advisory Board Member', 
    text: 'Over  15  years  in  global  art  markets.  Partner  at  Hedonist  Gallery  in Dubai, representing iconic Spanish artists. Led over 50 international projects  and  exhibitions.  Founder  of  Authentic  Boutique  LLC,  a consultancy agency building cross-cultural relationships in the Middle East.  Master’s  degree  in  Culture  and  Religion  Science,  Art  of  the Modern Period from UvA, and an MBA in Exponential Innovation from Singularity  University,  promoting  synergies  between  art,  marketing, and technology.'
  }, */ 
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/patrick.png',
    title: 'Patrick Foret',
    role: 'Advisory Board Member', 
    text: 'Founder of Cultural Enterprise Ltd, Patrick advises various organizations, destinations, and brands on driving impact through the arts. With a history of C-level roles in leading arts organizations, he notably created and developed the Art Basel global partnerships and the Art Basel Cities programs (2010 to 2020), played a central role at TDIC on the Louvre and Guggenheim Abu Dhabi projects, and served as CEO of Aorist, a next-generation cultural institution fusing art with technology. As a Strategic Advisor, Patrick contributed to many organizations\' engagement in the arts, including the Boston Consulting Group and Bain & Company on cultural development advisory projects, and supported the global expansion of Design Miami. Patrick focuses on high-impact cultural projects across Asia, the Middle East, Europe, and the Americas.'
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/frank_lasry.png',
    title: 'Frank Lasry',
    role: 'Advisory Board Member', 
    text: 'Raised between France and Morocco, Frank joined Christie\'s, where he held senior positions across Paris, London, Dubai, and Hong Kong, specializing in Operations and Business Management. In 2015, he joined Phillips, in London, as Chief Operating Officer, then joined Art Basel in charge of all operations, financial management and human resources. Within Art Basel, Frank led the launch of Art Basel in Paris. A seasoned executive, Frank has extensive experience in operations management, financial strategy, international team leadership, and the execution of large-scale projects, including M&A. His career spans Europe, New York, the Middle East, and Asia. His previous leadership roles include positions at MCH Group, Phillips, and numerous projects as an independent consultant. He holds an MBA from Cass Business School and brings expertise in art exhibitions, event management, and museums.'
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/sarah-malik-new.png',
    title: 'Sarah Malik, FCIArb',
    role: 'Advisory Board Member', 
    text: 'Sarah is an accomplished lawyer and the founder of SOL International Ltd. A passionate advocate for female entrepreneurs, she advises tech startups through She Wins Arabia, a joint initiative between the International Finance Corporation and Abu Dhabi Global Market. Her leadership and expertise have earned her recognition as a finalist for the \'Most Influential Woman in Blockchain and Crypto\' at the 2022 Middle East Blockchain Awards. As a highly sought-after speaker, Sarah regularly presents at prominent conferences across the UAE, GCC, and internationally. Beyond her speaking engagements, she trains institutions and judiciaries worldwide and serves as a visiting lecturer in the LLM program at Middlesex University, Dubai.'
  }, /* {
    image: 'https://storage.googleapis.com/dev-nftrends-media/team/Tatiana_gecmen_valdek.png',
    title: 'Tatiana Gecmen-Waldek',
    role: 'Advisory Board Membe', 
    text: 'Over  15  years  in  contemporary  art  advisory. Creative  Director  at Serpetinis  and  Curator  for  Joali  Resort  Maldives.  Client  portfolio includes  Giorgio  Armani,  Etro,  Roger  Vivier,  Cartier,  Maison  Kitsuné, and Diptyque. Member of the Artistic Committee of Asia Now Art Fair in Paris.  Produced  and  curated  multiple  exhibitions  in  major  European cities.  Under  the  pseudonym  BadBadTati,  collaborates  closely  with video artists, electronic music producers, and luxury brands.'
  } */
];

export const teamTeachnologyData = [
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-Thomas%20Hardjono-450.jpg',
    title: 'Thomas Hardjono, PhD',
    role: 'Co-Founder & Chief Technology Officer', 
    text: 'In addition to his role at NFtrends, Thomas is also the Chief Technology Officer of MIT Connection Science and the MIT Trust-Data Consortium, and Director of MIT Kerberos Network Authentication Systems—used by 70% of enterprise systems globally. Thomas brings over 25 years of experience in cutting-edge technology. His previous roles include Principal Scientist at Verisign/Symantec, Chief Scientist at Wave Trusted Hardware, and CTO at SignaCert / Harris Defense. A recognized leader in Web3 decentralized infrastructures and cyber-resilient protocols, Thomas has guided innovative startups through standardization efforts across major industry forums, including the IETF and the Trusted Computing Group. A prolific author, he has published over 80 technical papers, written five books on cybersecurity, and holds more than 40 issued patents.'
  }, {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-images%20(6).jpeg',
    title: 'Prof. Alexander Lipton, PhD',
    role: 'Advisory Board Member', 
    text: 'Alexander is widely regarded as one of the most respected quants of his generation. He currently serves as Global Head of Quantitative Research and Development and Portfolio Oversight at the Abu Dhabi Investment Authority (ADIA). His previous roles include Managing Director and Co-Head of the Quant Solutions Group at Bank of America, as well as executive positions at Citadel, Credit Suisse, and Bankers Trust. An MIT Connection Science Fellow and two-time recipient of Risk Magazine\'s Quant of the Year award, Alexander has authored several influential books on subjects ranging from magnetohydrodynamics to financial engineering and blockchain technology.', 
  }, {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-image%20(16).png',
    title: 'Prof. Alexander Pentland, PhD',
    role: 'Advisory Board Member', 
    text: 'Sandy is one of the most-cited computational scientists and authors globally, recognized by Forbes in 2012 as one of the "seven most powerful data scientists in the world," alongside the founders of Google. He is the founding faculty director of the MIT Connection Science Research Initiative and the creator and director of MIT’s Media Lab and the Center for Future Health. Sandy\'s distinguished accolades include being named a Stanford HAI Fellow, holding the MIT Toshiba Chair, serving as a Board Member of the UN Global Partnership for Sustainable Development Data, and advising on the ADIA Lab Advisory Board. His groundbreaking work with the World Economic Forum contributed to the creation of the EU GDPR privacy regulation, and his Data for Development experiments played a key role in shaping the UN’s Sustainable Development Goals.', 
  }, {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-StuartHaber.jpg',
    title: 'Stuart Haber, PhD',
    role: 'Advisory Board Member', 
    text: 'Widely regarded as one of the greatest cryptographers of our time, Stuart is a renowned cryptographer and computer scientist. He co-invented the blockchain technique that provided the foundational timestamping and authentication for digital records, paving the way for Bitcoin and modern blockchain technology. Stuart also co-founded Surety, a Bellcore spinoff and the first commercial application of blockchain technology. His expertise and leadership in the field have led him to serve on the Board of Directors of the International Association for Cryptologic Research (IACR).'
  }, 
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/cristina.png',
    title: 'Christina Mesropian, PhD',
    role: 'VP of AI & Machine Learning', 
    text: 'Christina is a scientific researcher with over 20 years of experience in artificial intelligence, machine learning, advanced feature engineering, and big data management at Fermi National Laboratory in the USA and CERN in Switzerland. She is also the Founder of Quant Data Solutions and has authored numerous publications in major scientific journals on particle physics, gravitation, and cosmology. An invited speaker at over 30 international conferences, Christina holds a Ph.D. in High Energy Physics from Rockefeller University, NY.'
  },
  {
    image: 'https://storage.googleapis.com/dev-nftrends-media/T-12131212.jpg',
    title: 'Alex Boiko',
    role: 'VP of Engineering', 
    text: 'Alex is a seasoned Senior Manager with over six years of extensive industrial experience. He is also a Co-Founder of TAG, a software development group, and has played a pivotal role in co-founding and leading engineering efforts at several companies. With expertise in strategic planning, resource allocation, and performance management for high-profile technology projects, Alex holds an MSc in Engineering with a specialization in Technical Data Protection from Donetsk National Technical University.'
  }
]

export const imagesCloud = {
    landing_bg_1_lg: 'https://storage.googleapis.com/dev-nftrends-media/landing_bg_1_lg_compressed.png',
    landing_bg_2_lg: 'https://storage.googleapis.com/dev-nftrends-media/landing_bg_2_lg_compressed.png',
    landing_bg_3_lg: 'https://storage.googleapis.com/dev-nftrends-media/landing_bg_3_lg_compressed.png',
    landing_bg_4_lg: 'https://storage.googleapis.com/dev-nftrends-media/landing_bg_4_lg.png',
    section_2_bg_lg: 'https://storage.googleapis.com/dev-nftrends-media/section_2_bg_lg_compressed.png',
    section_3_bg_lg: 'https://storage.googleapis.com/dev-nftrends-media/section_3_bg_lg_compressed.png',
    section_4_bg: 'https://storage.googleapis.com/dev-nftrends-media/section_4_bg.jpg',
    // example_1_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_1_nfdi.jpg',
    example_1_lg: 'https://storage.googleapis.com/dev-nftrends-media/NFDI3-min.jpg',
    example_2_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_2_lg.png',
    example_3_lg: 'https://storage.googleapis.com/dev-nftrends-media/section_4_item_1.jpeg',
    example_4_lg: 'https://storage.googleapis.com/dev-nftrends-media/section_5_item_1.jpeg',
    example_5_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_11.jpg',
    example_5_sm: 'https://storage.googleapis.com/dev-nftrends-media/example_5_sm.png', 
    example_6_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_12.jpg',
    example_7_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_12_2.jpg',
    example_7_sm: 'https://storage.googleapis.com/dev-nftrends-media/example_7_sm.png',
    example_8_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_9.jpg',
    example_8_sm: 'https://storage.googleapis.com/dev-nftrends-media/example_8_sm.png',
    example_9_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_10.jpg',
    example_1_1_lg: 'https://storage.googleapis.com/dev-nftrends-media/section_1_item_1.png',
    example_3_nfdi: 'https://storage.googleapis.com/dev-nftrends-media/example_3_nfdi.jpg',
    example_4_nfdi: 'https://storage.googleapis.com/dev-nftrends-media/example_4_nfdi.jpg',
    example_1_2_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_1_2_lg.JPG',
    // example_2_2_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_2_2_nfdi.jpeg',
    example_2_2_lg: 'https://storage.googleapis.com/dev-nftrends-media/example_1_nfdi.jpg',
    example_4_2_dpp: 'https://storage.googleapis.com/dev-nftrends-media/example_4_2_dpp_new.jpg',
    example_4_2_nfdi: 'https://storage.googleapis.com/dev-nftrends-media/NFDI4.%20Art%20and%20tech%20AiBYTEs%201-min.jpg', 
    example_5_2_dpp: 'https://storage.googleapis.com/dev-nftrends-media/example_5_2_dpp.JPG',
    example_5_2_nfdi: 'https://storage.googleapis.com/dev-nftrends-media/example_5_2_nfdi.jpg',
}

export const imagesLanding = {
  example_1_lg: [
    {id: 1, path_url: 'https://storage.googleapis.com/dev-nftrends-media/section_1_item_1.png'},
    {id: 2, path_url: 'https://storage.googleapis.com/dev-nftrends-media/section_1_item_2.jpg'}
  ], 
  example_3_lg: [
    {id: 1, path_url: 'https://storage.googleapis.com/dev-nftrends-media/section_4_item_1.jpeg'},
    {id: 2, path_url: 'https://storage.googleapis.com/dev-nftrends-media/section_4_item_2.jpeg'}
  ],
  example_4_lg: [
    {id: 1, path_url: 'https://storage.googleapis.com/dev-nftrends-media/section_5_item_1.jpeg'},
    {id: 2, path_url: 'https://storage.googleapis.com/dev-nftrends-media/section_5_item_2.jpeg'}
  ]
}


export const get_section_4_data = (t:any) => ([
    {
      id: 1,
      name: 'section_4_2',
      title: t('landing.section_4_title_2'),
      text: t('landing.section_4_text_2'),
    },
/*     {
      id: 2,
      name: 'section_4_1',
      title: t('landing.section_4_title_1'),
      text: t('landing.section_4_text_1'),
    }, */
    {
      id: 3,
      name: 'section_4_5',
      title: t('landing.section_4_title_2_1'),
      text: t('landing.section_4_text_2_1'),
    },
    {
      id: 4,
      name: 'section_4_3',
      title: t('landing.section_4_title_3'),
      text: t('landing.section_4_text_3'),
    },
    {
      id: 5,
      name: 'section_4_4',
      title: t('landing.section_4_title_3_1'),
      text: t('landing.section_4_text_3_1'),
    },
  ]);



  export const get_section_4_1_data = (t: any) => ([
    {
      id: 1,
      name: 'section_4_1_1',
      title: t('landing.section_4_1_title_1'),
      text: t('landing.section_4_1_text_1'),
    },
    {
      id: 2,
      name: 'section_4_2_1',
      title: t('landing.section_4_1_title_2'),
      text: t('landing.section_4_1_text_2'),
    },
    {
      id: 3,
      name: 'section_4_3_1',
      title: t('landing.section_4_1_title_3'),
      text: t('landing.section_4_1_text_3'),
    },
    {
      id: 4,
      name: 'section_4_4_1',
      title: t('landing.section_4_1_title_4'),
      text: t('landing.section_4_1_text_4'),
    },
  ]);

  export const get_section_6_data = (t:any) => ([
    {
      id: 1,
      name: 'section_6_1',
      title: t('landing.section_6_title_1'),
      text: t('landing.section_6_text_1'),
    },
    {
      id: 2,
      name: 'section_6_2',
      title: t('landing.section_6_title_2'),
      text: t('landing.section_6_text_2'),
    },
    {
      id: 3,
      name: 'section_6_3',
      title: t('landing.section_6_title_3'),
      text: t('landing.section_6_text_3'),
    },
    {
      id: 4,
      name: 'section_6_4',
      title: t('landing.section_6_title_4'),
      text: t('landing.section_6_text_4'),
    },
  ]);

  export const get_subsection_5_2_dpp_data = (t: any) => ([
    {
      id: 1,
      name: 'subsection_5_2_dpp_1',
      title: t('landing.subsection_5_2_title_1'),
      text: t('landing.subsection_5_2_text_1'),
    },
    {
      id: 2,
      name: 'subsection_5_2_dpp_2',
      title: t('landing.subsection_5_2_title_2'),
      text: t('landing.subsection_5_2_text_2'),
    },
    {
      id: 3,
      name: 'subsection_5_2_dpp_3',
      title: t('landing.subsection_5_2_title_3'),
      text: t('landing.subsection_5_2_text_3'),
    },
    {
      id: 4,
      name: 'subsection_5_2_dpp_4',
      title: t('landing.subsection_5_2_title_4'),
      text: t('landing.subsection_5_2_text_4'),
    },
  ]);

  export const get_subsection_5_3_nfdi_data = (t:any) => ([
    {
      id: 1,
      name: 'subsection_5_3_nfdi_1',
      title: t('landing.subsection_5_3_title_1'),
      text: t('landing.subsection_5_3_text_1'),
    },
    {
      id: 2,
      name: 'subsection_5_3_nfdi_2',
      title: t('landing.subsection_5_3_title_2'),
      text: t('landing.subsection_5_3_text_2'),
    },
    {
      id: 3,
      name: 'subsection_5_3_nfdi_3',
      title: t('landing.subsection_5_3_title_3'),
      text: t('landing.subsection_5_3_text_3'),
    },
    {
      id: 4,
      name: 'subsection_5_3_nfdi_4',
      title: t('landing.subsection_5_3_title_4'),
      text: t('landing.subsection_5_3_text_4'),
    },
    {
      id: 5,
      name: 'subsection_5_3_nfdi_5',
      title: t('landing.subsection_5_3_title_5'),
      text: t('landing.subsection_5_3_text_5'),
    },
    {
      id: 6,
      name: 'subsection_5_3_nfdi_6',
      title: t('landing.subsection_5_3_title_6'),
      text: t('landing.subsection_5_3_text_6'),
    },
  ]);

  export const get_section_6_data_dpp = (t:any) => ([
    {
      id: 1,
      name: 'section_6_1_dpp',
      title: t('landing.section_6_title_1_dpp'),
      text: t('landing.section_6_text_1_dpp'),
    },
    {
      id: 2,
      name: 'section_6_2_dpp',
      title: t('landing.section_6_title_2_dpp'),
      text: t('landing.section_6_text_2_dpp'),
    },
    {
      id: 3,
      name: 'section_6_3_dpp',
      title: t('landing.section_6_title_3_dpp'),
      text: t('landing.section_6_text_3_dpp'),
    },
    {
      id: 4,
      name: 'section_6_4_dpp',
      title: t('landing.section_6_title_4_dpp'),
      text: t('landing.section_6_text_4_dpp'),
    },
/*     {
      id: 5,
      name: 'section_6_5_dpp',
      title: t('landing.section_6_title_5_dpp'),
      text: t('landing.section_6_text_5_dpp'),
    }, */
  ]);

  export const get_section_7_data = (t:any) => ([
    {
      id: 1,
      name: 'section_7_1',
      title: t('landing.section_7_title_1'),
      text: t('landing.section_7_text_1'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_1.svg',
    },
    {
      id: 2,
      name: 'section_7_2',
      title: t('landing.section_7_title_2'),
      text: t('landing.section_7_text_2'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_2.svg',
    },
    {
      id: 3,
      name: 'section_7_3',
      title: t('landing.section_7_title_3'),
      text: t('landing.section_7_text_3'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_7.svg',
    },
    {
      id: 4,
      name: 'section_7_4',
      title: t('landing.section_7_title_4'),
      text: t('landing.section_7_text_4'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_4.svg',
    },
    {
      id: 5,
      name: 'section_7_5',
      title: t('landing.section_7_title_5'),
      text: t('landing.section_7_text_5'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_3.svg',
    },
    {
      id: 6,
      name: 'section_7_6',
      title: t('landing.section_7_title_6'),
      text: t('landing.section_7_text_6'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_6.svg',
    },
    {
      id: 7,
      name: 'section_7_7',
      title: t('landing.section_7_title_7'),
      text: t('landing.section_7_text_7'),
      icon_url: 'https://storage.googleapis.com/dev-nftrends-media/hiw_5.svg',
    },
  ]);

  export const newsData = [
    {
      id: 'news_1',
      title: 'Redefining Authenticity in the Digital Age with Marsha Lipton PH.D: The Synergy of Digital Product Passports and Fingerprints...',
      text: 'In the evolving landscape of Web3, Digital Product Passports (DPPs) and Digital Product Fingerprints are pioneering new avenues for redefining ownership and authenticity. These innovative tools are reshaping how businesses approach transparency...', 
      date: 'June 03, 2024',
      url: 'https://www.theworldstimes.com/interviews/marsha-lipton/',
      url_image: 'https://storage.googleapis.com/dev-nftrends-media/team/news_images/news-1.jpg', 
    },
    {
      id: 'news_2',
      title: 'Redefining Authenticity in the Digital Age with Marsha Lipton PH.D: The Synergy of Digital Product Passports and Fingerprints in Ownership and Trustworthiness',
      text: 'In the evolving landscape of Web3, Digital Product Passports (DPPs) and Digital Product Fingerprints are pioneering new avenues for redefining ownership and authenticity. These innovative tools are reshaping how businesses approach transparency...', 
      date: 'June 03, 2024',
      url: 'https://www.theworldstimes.com/interviews/marsha-lipton/'
    },
    {
      id: 'news_3',
      title: 'The Top 10 Women Entrepreneurs to Watch in 2024: Uncovering Revolutionary Stories',
      text: 'Over the past few years, a lot of things have happened and when we talk about the past few decades, it’s more than a lot that’s taken place. However, one consistent thing that led to a revolution...', 
      date: 'May 21, 2024',
      url: 'https://entrepreneurmirror.com/top-10-women-entrepreneurs-in-2024/',
      url_image: 'https://storage.googleapis.com/dev-nftrends-media/team/news_images/Top-10-Women-Entrepreneurs-to-Watch-in-2024-Uncovering-Revolutionary-Stories.jpg'
    },
    {
      id: 'news_4',
      title: 'The Top 10 Women Entrepreneurs to Watch in 2024: Uncovering Revolutionary Stories',
      text: 'Over the past few years, a lot of things have happened and when we talk about the past few decades, it’s more than a lot that’s taken place. However, one consistent thing that led to a revolution...', 
      date: 'May 21, 2024',
      url: 'https://entrepreneurmirror.com/top-10-women-entrepreneurs-in-2024/'
    },
    {
      id: 'news_5',
      title: 'Top 10 Female Leaders Revolutionizing the Gulf Region',
      text: 'Marsha Lipton, with a distinguished career on Wall Street and in the City of London, culminated her active trading career as a Managing Director at JPMorgan Chase...', 
      date: 'March 8, 2024',
      url: 'https://theemiratestimes.com/top-10-female-leaders-revolutionizing-the-gulf-region/#5_Marsha_Lipton',
      url_image: 'https://storage.googleapis.com/dev-nftrends-media/team/news_images/Lifestyle-Editorial-Business-Women-Entrepreneurs-Keynotes-Presentation-1-750x422.jpg'
    },
    {
      id: 'news_6',
      title: 'Exclusive Solutions For Luxury And Art Market Success With NFTrends: Marsha Lipton',
      text: 'We recently had the opportunity to interview Marsha Lipton, CEO of Numeraire Future Trends (NFTrends). With a history of over...', 
      date: 'February 13, 2024',
      url: 'https://theemiratestimes.com/meet-marsha-lipton/',
      url_image: 'https://storage.googleapis.com/dev-nftrends-media/team/news_images/Marsha-Lipton-1-750x469.png'
    },
    {
      id: 'news_7',
      title: 'So you bought an NFT? I\'m not going to say "I told you so"...',
      text: 'Although NTFs – non-fungible tokens – have recently been declared dead, this is not going to be an "I told you so" piece.', 
      date: 'September 28, 2023',
      url: 'https://www.thenationalnews.com/opinion/comment/2023/09/28/nfts-digital-art-bubble-market/',
      url_image: 'https://storage.googleapis.com/dev-nftrends-media/team/news_images/LJP6N3RLBVCZPJL4RVH5QR5RHI.avif'
    },
  ];

  export const updatedNewsData  = [
    {
      id: 'news_1',
      title: 'Colloboration with OUCHHH, the Leading Innovative New Media Studio',
      date: '01/10/2024',
      url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/featured-bg.png',
      url_redirect: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/NFtrends%20X%20OUCHHH_v.2.pdf'
    },
    {
      id: 'news_3',
      title: 'Top 10 Women Entrepreneurs to Watch in 2024: Uncovering Revolutionary Stories',
      date: '21/05/2024',
      url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/news_3.png',
      url_redirect: 'https://entrepreneurmirror.com/top-10-women-entrepreneurs-in-2024/'
    },
    {
      id: 'news_3',
      title: 'Redefining Authenticity in the Digital Age with Marsha Lipton PH.D: The Synergy of Digital Product Passports and Fingerprints...',
      date: '03/05/2024',
      url: 'https://storage.googleapis.com/dev-nftrends-media/team/news_images/news-1.jpg',
      url_redirect: 'https://www.theworldstimes.com/interviews/marsha-lipton/', 
    },
  ]

  export const follow_us_icons = [
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-1.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-2.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-3.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-4.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-5.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-6.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-7.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-8.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/l-9.jpg',
  ];

  export const partners_icons = [
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/partners-1.svg', partner_url: 'https://www.culturalenterprise.art/'}, 
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/sutton_logo.svg', partner_url: 'https://suttoncomms.com/'}, 
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/1Art.Private--1.png', partner_url: 'https://artprivate.com/'},
     {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/Dysnix.svg', partner_url: 'http://dysnix.com/'}, 

     {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/partners-4.svg', partner_url: 'https://www.metaminds.group/'}, 
     {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/White%20logo%20-%20no%20background.svg', partner_url: 'http://www.artluxe.io/'},

    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/UAEangelsLogo%20%D0%BA%D0%BE%D0%BF%D0%B8%D1%8F%201.svg', partner_url: ''}, 
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/partners-5.svg', partner_url: 'http://www.sarahmalik.net/'}, 
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/partners-6.svg', partner_url: 'https://turumburum.com/'}, 
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/sol-logo.png', partner_url: 'http://www.sol-intl.com/'}, 
    {url: 'https://storage.googleapis.com/dev-nftrends-media/new-landing/hilton.png', partner_url: 'https://hiltoncontemporary.com/?v=692e8df1db82'}, 
  ];

 export const carousel_images = [
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/main-lg-1.png',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/main-lg-2.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/main-lg-3.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/wallet-slider-item.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/main-lg-5.jpg',
    'https://storage.googleapis.com/dev-nftrends-media/new-landing/main-5.png',
  ]; 

  export const anchors = {
    ABOUT: 'about', 
    TEAM: 'team', 
    NEWS: 'news', 
    CONTACT: 'contact', 
    SOLUTIONS: 'solutions', 
  }
